import { getTemplateSrv } from "@grafana/runtime";
import copy from 'copy-to-clipboard';
import { decode } from "@msgpack/msgpack";

export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  live: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

export type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limitasf
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type of unit
  live: string;
  mne: string;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export type VisibleTooltips = { [key: string]: boolean };

export interface Dbdata{
  telemetry: number;
  unit: string;
  //limit: LimitProp;
  limit: string;
live: string;
mne: string;
spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      className: string;
      id: string;
      backgroundImage: string;
      current: TLM;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
}

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export interface ProcessedData {
  [key: string]: { cnvValue: string, mne: string, name: string }[];
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value.toLowerCase() === mne.toLowerCase()) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const nameToMne = {

  /* Sun Sensors */
  "SS1 Intensity": "adss1int", /* Sun Sensor 1 Intensity*/
  "SS2 Intensity": "adss2int", /* Sun Sensor 2 Intensity*/
  "SS3 Intensity": "adss3int", /* Sun Sensor 3 Intensity*/
  "SS4 Intensity": "adss4int", /* Sun Sensor 4 Intensity*/
  "SS5 Intensity": "adss5int", /* Sun Sensor 5 Intensity*/
  "SS6 Intensity": "adss6int", /* Sun Sensor 6 Intensity*/
  "SS7 Intensity": "adss7int", /* Sun Sensor 7 Intensity*/
  "SS8 Intensity": "adss8int", /* Sun Sensor 8 Intensity*/
  "SS9 Intensity": "adss9int", /* Sun Sensor 9 Intensity*/
  "SS10 Intensity": "adss10int", /* Sun Sensor 10 Intensity*/
  "SS11 Intensity": "adss11int", /* Sun Sensor 11 Intensity*/
  "SS12 Intensity": "adss12int", /* Sun Sensor 12 Intensity*/

  "SS1 Data Used": "adss1duf", /* Sun Sensor 1 Data Used Flag*/
  "SS2 Data Used": "adss2duf", /* Sun Sensor 2 Data Used Flag*/
  "SS3 Data Used": "adss3duf", /* Sun Sensor 3 Data Used Flag*/
  "SS4 Data Used": "adss4duf", /* Sun Sensor 4 Data Used Flag*/
  "SS5 Data Used": "adss5duf", /* Sun Sensor 5 Data Used Flag*/
  "SS6 Data Used": "adss6duf", /* Sun Sensor 6 Data Used Flag*/
  "SS7 Data Used": "adss7duf", /* Sun Sensor 7 Data Used Flag*/
  "SS8 Data Used": "adss8duf", /* Sun Sensor 8 Data Used Flag*/
  "SS9 Data Used": "adss9duf", /* Sun Sensor 9 Data Used Flag*/
  "SS10 Data Used": "adss10duf", /* Sun Sensor 10 Data Used Flag*/
  "SS11 Data Used": "adss11duf", /* Sun Sensor 11 Data Used Flag*/
  "SS12 Data Used": "adss12duf", /* Sun Sensor 12 Data Used Flag*/

  /* star trackers */
  "St Pwr": "STPW", /* Star Tracker Power */
  "St Mode": "stmode", /* Star Tracker Mode */
  "St Att Status": "adstast", /* Star Tracker Attitude Status: */
  "St Used?": "adstaest", /* Used for Attitude Estimation */
  "St Synch Status": "stsynchst", /* Star Tracker Synch. Status: */
  "St Last Err Id": "sterrid", /* Star Tracker Last Err Identifier */
  "St Oh1 Expected Stars": "stexstarsoh1", /* Number of Expected Stars: */
  "St Oh2 Expected Stars": "stexstarsoh2", /* Number of Expected Stars: */
  "St Oh1 # Coh Stars": "stnumcohstarsoh1", /* Number of Coherent Stars */
  "St Oh2 # Coh Stars": "stnumcohstarsoh1", /* Number of Coherent Stars */
  "St Oh1 Oh Stat": "stoh1st", /* */
  "St Oh2 Oh Stat": "stoh2st", /* */

  /* IRU */
  "IRU Pwr": "irupw", /* IRU Switched Power */
  "IRU Gryo1 data used": "adgya1duf", /* Gyro Axis Data Used Flag */
  "IRU Gryo2 data used": "adgya2duf", /* Gyro Axis Data Used Flag */
  "IRU Gryo3 data used": "adgya3duf", /* Gyro Axis Data Used Flag */
  "IRU Gryo Est Status": "adrsbiasst", /* Gyro Bias Estimation Status */
  "IRU Sensor Good": "adirugood", /* Rate Sensor Good Throughout */
  "IRU Message Status": "adirumsg", /* IRU FSW Auto-Messaging Status */


  /* Reaction Wheels */
  "RW1 Pwr": "adrw1pw", /* Reaction Wheel 1 Power*/
  "RW2 Pwr": "adrw2pw", /* Reaction Wheel 2 Power*/
  "RW3 Pwr": "adrw3pw", /* Reaction Wheel 3 Power*/
  "RW4 Pwr": "adrw4pw", /* Reaction Wheel 4 Power*/

  "RW1 Speed/dir": "adrw1sdir", /* Reaction Wheel 1 Speed/Dir*/
  "RW2 Speed/dir": "adrw2sdir", /* Reaction Wheel 1 Speed/Dir*/
  "RW3 Speed/dir": "adrw3sdir", /* Reaction Wheel 1 Speed/Dir*/
  "RW4 Speed/dir": "adrw4sdir", /* Reaction Wheel 1 Speed/Dir*/

  /* Thrusters */
  "Thruster 1 Fire": "adt1firet", /* Fire */
  "Thruster 1 Cmd Start": "adt1cmdot", /* Cmd Start */
  "Thruster 1 Stop": "adt1stopt", /* Stop */
  "Thruster 1 Duration": "adt1dur", /* Duration */
  "Thruster 1 Duty": "adt1dty", /* Duty */
  "Thruster 1 Cat Bed Temp": "t46thrustercatbed1t", /* Cat Bed Temp */
  "Thruster 2 Fire": "adt2firet", /* Fire */
  "Thruster 2 Cmd Start": "adt2cmdot", /* Cmd Start */
  "Thruster 2 Stop": "adt2stopt", /* Stop */
  "Thruster 2 Duration": "adt2dur", /* Duration */
  "Thruster 2 Duty": "adt2dty", /* Duty */
  "Thruster 2 Cat Bed Temp": "t44thrustercatbed2t", /* Cat Bed Temp */
  "Thruster 3 Fire": "adt3firet", /* Fire */
  "Thruster 3 Cmd Start": "adt3cmdot", /* Cmd Start */
  "Thruster 3 Stop": "adt3stopt", /* Stop */
  "Thruster 3 Duration": "adt3dur", /* Duration */
  "Thruster 3 Duty": "adt3dty", /* Duty */
  "Thruster 3 Cat Bed Temp": "t43thrustercatbed3t", /* Cat Bed Temp */
  "Thruster 4 Fire": "adt4firet", /* Fire */
  "Thruster 4 Cmd Start": "adt4cmdot", /* Cmd Start */
  "Thruster 4 Stop": "adt4stopt", /* Stop */
  "Thruster 4 Duration": "adt4dur", /* Duration */
  "Thruster 4 Duty": "adt4dty", /* Duty */
  "Thruster 4 Cat Bed Temp": "t45thrustercatbed4t", /* Cat Bed Temp */
  
  /* Summary 1 */
  "Launch Mode": "adcslaunch", /* Launch Mode*/
  "Current State": "adstate", /* Current ADCS State */
  "Current Solution": "adsolution", /* Current ADCS Solution State */
  "Orbit State": "adostate", /* Orbital State Valid */
  "Attitude Valid": "adattvalid", /* Attitude Valid */
  "Target Table": "adtargt", /* Target Table */
  "Fixed Frame Table": "ADFFTID", /* Fixed Frame Table */
  "Desired Control Frame": "ADDCFRAME", /* Desired Control Frame */
  "Desired Reference Frame": "ADDRFRAME", /* Desired Reference Frame */
  "Sun Dist": "adscsundist", /* Spacecraft to Sun Distance */
  "ICP Flag": "swicpflagst", /* ICP Flag */
  "Maneuver Done": "admandone", /* Maneuver Done */
  "Pos X": "adnposx", /* Next Orbital Position X */
  "Pos Y": "adnposy", /* Next Orbital Position Y */
  "Pos Z": "adnposz", /* Next Orbital Position Z */
  "Vel X": "adnvelx", /* Next Orbital Velocity X */
  "Vel Y": "adnvely", /* Next Orbital Velocity Y */
  "Vel Z": "adnvelz", /* Next Orbital Velocity Z */

  /* Summary 2 */
  
  /* Summary 3 */
  "Total Sys X": "admomentx", /* Total System X */
  "Total Sys Y": "admomenty", /* Total System Y */
  "Total Sys Z": "admomentz", /* Total System Z */
  "Body X": "adbmomx", /* Body X */
  "Body Y": "adbmomy", /* Body Y */
  "Body Z": "adbmomz", /* Body Z */
  "Thruster Mom": "adthrmomst", /* Thruster Momentum control status */

  /* Summary 4 */
  "Thrusters Active": "adthact", /* Thrusters Active */
  "Thruster Fault": "adthrf", /* Thruster Fault */
  "DeSat In Progress": "addesat", /* DeSat In Progress */
  "DeSat Fault": "addesatf", /* DeSat Fault */
  "Latch Valve Pos": "prlvpos", /* Latch Valve Position */
  "Cat Bed Htr Pwr Pri": "htrcatppw", /*  */
  "Cat Bed Htr Pwr Red": "htrcatrpw", /*  */
  "Thruster 1&2 Arm Status": "pwpapi2lparmpw", /*  */
  "Thruster 3&4 Arm Status": "pwpapi3lparmpw", /*  */

  /* Sun Vector */

  /* Quaternion */
  "Q1": "admattq1", /* Quaternion 1*/
  "Q2": "admattq2", /* Quaternion 2*/
  "Q3": "admattq3", /* Quaternion 3*/
  "Q4": "admattq4", /* Quaternion 4*/
}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){

if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
  return 'red-limit';
}
else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
    return 'yellow-limit';
}else{
  return 'gray-limit';
}
}

const variableName = 'ScName'; // Replace with your Grafana variable
export const source = getTemplateSrv().replace(`\${${variableName}}`);


export const handleCopyMneName = (mne: string) => {
  copy(mne);
};


export const decodeFunc = (data: string) => {
  const binaryString = window.atob(data);
        
  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  // Decode the MessagePack data
  const decodedObject = decode(bytes);
  
  return decodedObject;
}

export type redisDataType = {
  apid: number;
  cnv: string;
  facility: string;
  is_pseudo: boolean;
  mne: string;
  raw: number;
  scid: number;
  t_insert: number;
  t_pkt: number;
  vcid: number;
};


export function determineLimit(stringValue: string, limit: LimitProp){

  const value = parseFloat(stringValue);

  if (limit.rl !== null && value < limit.rl){
    return 'rl';
  }else if (limit.rh !== null && value > limit.rh){
    return 'rh';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'rl';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'rh';
  }
  else {
    return "";
  }
}