import React, { useCallback, useState } from 'react';
import { css, cx, keyframes } from '@emotion/css';
import { InnerData, TLM, VisibleTooltips } from 'utils/type';
import * as scheme from './Scheme';
import { determineColorNew, handleCopyMneName } from 'utils/function';
import PlotlyChart from './PlotlyChart';

interface ThrusterProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
    fire: TLM;
  };
}

const Thruster: React.FC<ThrusterProp> = ({ data }) => {
  const [tooltipStates, setTooltipStates] = useState({
    tooltips: {} as VisibleTooltips,
    limitPops: {} as VisibleTooltips,
  });

  // Tooltip handlers
  const handleTooltipInteraction = useCallback(
    (type: 'tooltip' | 'limitPop', key?: string, duration: number = 2000) => {
      setTooltipStates((prev) => {
        const newState = { ...prev };

        if (key) {
          const stateKey = type === 'tooltip' ? 'tooltips' : 'limitPops';
          newState[stateKey] = {
            ...prev[stateKey],
            [key]: !prev[stateKey][key],
          };

          if (type === 'tooltip') {
            setTimeout(() => {
              setTooltipStates((current) => ({
                ...current,
                tooltips: {
                  ...current.tooltips,
                  [key]: false, 
                },
              }));
            }, duration);
          }
        }
        return newState;
      });
    },
    []
  );

  const handleMneNameClick = useCallback(
    (key: string) => {
      handleTooltipInteraction('tooltip', key);
    },
    [handleTooltipInteraction]
  );

  const handleValueChartClick = useCallback(
    (key: string) => {
      handleTooltipInteraction('limitPop', key, 0);
    },
    [handleTooltipInteraction]
  );

  const fireOn = data.fire?.telemetry === 'ON';

  return (
    <div
      className={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      `}
    >
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="300px" viewBox="0 0 200 300">
          <rect x="65" y="20" width="80" height="160" fill="#555" stroke="#333" stroke-width="4" />

          <path d="M65,180 L45,230 L165,230 L145,180 Z" fill="#444" stroke="#333" stroke-width="4" />

          {fireOn && (
            <g>
              <path d="M75,230 C65,260 105,300 105,300 C105,300 145,260 135,230 Z" fill="orange" opacity="0.8" />

              <path d="M85,230 C75,250 105,280 105,280 C105,280 135,250 125,230 Z" fill="red" opacity="0.7" />
              <ellipse cx="105" cy="270" rx="12" ry="30" fill="yellow" opacity="0.6" />
            </g>
          )}

          <circle cx="105" cy="100" r="15" fill={fireOn ? 'green' : '#999'} />
          <rect x="93" y="40" width="24" height="15" fill="#999" />
        </svg>

        <p>{data.name}</p>
      </div>

      <div className={scheme.textboxItems}>
        {data.value &&
          Object.entries(data.value).map(([key, innerData]) => (
            <div className={cx(scheme.svgValueSpanContainer, css`width: 300px`)} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer, css`width:60%`)} onClick={() => handleMneNameClick(key)}>
                {key}
                {tooltipStates.tooltips[key] && (
                  <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                    {innerData.dbData.mne}
                  </span>
                )}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>
                  {innerData.fname}
                </span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`
                    color: ${innerData.dbData?.live || ''};
                  `,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData?.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {tooltipStates.limitPops[key] && (
                <div className={scheme.chartContainer}>
                  <PlotlyChart
                    data={{
                      mne: innerData.dbData.mne,
                      sc: innerData.dbData.spacecraft,
                    }}
                  />
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Thruster;
