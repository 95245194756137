import { css } from '@emotion/css';

export const lna = css`
  width: 400px;
`;

export const stohContainer = css`
  width: 600px;

  & > div {

    & > div{

      & > div:nth-of-type(2){
        width: 250px;
      }
    }
    
  }
`;

// Define a type for the class keys
export type ClassKey = 'lna' | 'stohContainer';