import { Drawer } from '@grafana/ui'
import React from 'react'

interface DrawerComponentProps {
    isOpen: boolean;
    onClose: () => void;
    keyData: { [key: string]: string }
  }

  const DrawerComponent: React.FC<DrawerComponentProps> = ({ isOpen, onClose, keyData }) => {
    return (
    <div>
        {isOpen && <Drawer title="Name to Mnemonics Translation" subtitle="name: mne" size="sm" onClose={() => onClose()}>
            {Object.entries(keyData).map(([key, value]) => (
                <div key={key}>
                    <p>{key}: {value}</p>
                </div>
            ))}
        </Drawer>}
    </div>
  )
}

export default DrawerComponent

