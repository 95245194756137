// createNodes.ts

import { TelemetryDictionary } from 'utils/type';
import { Node } from 'reactflow';

export const createNodes = (
  telemetryDictionary: TelemetryDictionary,
  pbTime: string,
  pbLoop: number,
) => {
  const nodes: Node[] = [
    { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:575, y:150}, data:{'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},

    { id: 'sumBox4', type: 'sumBox4',parentNode:'', position:{x:2700, y:2400}, data:{name:"Thruster",catBedPri:telemetryDictionary['Cat Bed Htr Pwr Pri'],catBedRed:telemetryDictionary['Cat Bed Htr Pwr Red'],Thruster12Arm:telemetryDictionary['Thruster 1&2 Arm Status'],Thruster34Arm:telemetryDictionary['Thruster 1&2 Arm Status'],value1:{'Thrusters Active':{'dbData':telemetryDictionary['Thrusters Active'],'fname':''},"Thruster Fault":{"dbData":telemetryDictionary['Thruster Fault'],'fname':''},"Latch Valve Pos":{"dbData":telemetryDictionary['Latch Valve Pos'],'fname':''}}}},
    { id: 'sumBox3', type: 'sumBox3',parentNode:'', position:{x:2700, y:1700}, data:{totalSysX: telemetryDictionary['Total Sys X'],totalSysY: telemetryDictionary['Total Sys Y'],totalSysZ: telemetryDictionary['Total Sys Z'], value1:{"Mom Control":{"dbData":telemetryDictionary['Thruster Mom'],'fname':''},"DeSat In Progress":{"dbData":telemetryDictionary['DeSat In Progress'],'fname':''},"DeSat Fault":{"dbData":telemetryDictionary['DeSat Fault'],'fname':''}}, value2: {'Total Sys X':{'dbData':telemetryDictionary['Total Sys X'],'fname':''},'Total Sys Y':{'dbData':telemetryDictionary['Total Sys Y'],'fname':''},"Total Sys Z":{"dbData":telemetryDictionary['Total Sys Z'],'fname':''}, "Body X":{"dbData":telemetryDictionary['Body X'],'fname':''}, "Body Y":{"dbData":telemetryDictionary['Body Y'],'fname':''}, "Body Z":{"dbData":telemetryDictionary['Body Z'],'fname':''}}}},
    { id: 'sumBox1', type: 'sumBox1',parentNode:'', position:{x:2700, y:150}, data:{ value1:{'Launch Mode':{'dbData':telemetryDictionary['Launch Mode'],'fname':''},'Current State':{'dbData':telemetryDictionary['Current State'],'fname':''},"Current Solution":{"dbData":telemetryDictionary['Current Solution'],'fname':''},"Orbit State":{"dbData":telemetryDictionary['Orbit State'],'fname':''},"Attitude Valid":{"dbData":telemetryDictionary['Attitude Valid'],'fname':''},"Target Table":{"dbData":telemetryDictionary['Target Table'],'fname':''},"Fixed Frame Table":{"dbData":telemetryDictionary['Fixed Frame Table'],'fname':''},"Desired Control Frame":{"dbData":telemetryDictionary['Desired Control Frame'],'fname':''},"Desired Reference Frame":{"dbData":telemetryDictionary['Desired Reference Frame'],'fname':''},"Sun Dist":{"dbData":telemetryDictionary['Sun Dist'],'fname':''},"ICP Flag":{"dbData":telemetryDictionary['ICP Flag'],'fname':''},"Maneuver Done":{"dbData":telemetryDictionary['Maneuver Done'],'fname':''}}, value2: {'Pos X':{'dbData':telemetryDictionary['Pos X'],'fname':''},'Pos Y':{'dbData':telemetryDictionary['Pos Y'],'fname':''},"Pos Z":{"dbData":telemetryDictionary['Pos Z'],'fname':''}, "Vel X":{"dbData":telemetryDictionary['Vel X'],'fname':''}, "Vel Y":{"dbData":telemetryDictionary['Vel Y'],'fname':''}, "Vel Z":{"dbData":telemetryDictionary['Vel Z'],'fname':''}}}},

    { id: 'Star Tracker', type: 'sumBox5',parentNode:'', position:{x:1800, y:2200}, data:{name:'Star Tracker',className:'stContainer', value1:{'Pwr':{'dbData':telemetryDictionary['St Pwr'],'fname':''}, 'Mode':{'dbData':telemetryDictionary['St Mode'],'fname':''}, 'Attitude Status':{'dbData':telemetryDictionary['St Att Status'],'fname':''}, 'St Used for Est':{'dbData':telemetryDictionary['St Used?'],'fname':''}, 'Synch Status':{'dbData':telemetryDictionary['St Synch Status'],'fname':''}, "Last Err Id":{"dbData":telemetryDictionary['St Last Err Id'],'fname':''}}}},
    { id: 'St Oh2', type: 'textbox',parentNode:'', position:{x:1025, y:2550}, data:{name:'Star Tracker Optical Heads 2',className:'stohContainer', value:{'Oh state':{'dbData':telemetryDictionary['St Oh1 Oh Stat'],'fname':''},'Expected Stars':{'dbData':telemetryDictionary['St Oh1 Expected Stars'],'fname':''}, "Coh Stars":{"dbData":telemetryDictionary['St Oh1 # Coh Stars'],'fname':''}}}},
    { id: 'St Oh1', type: 'textbox',parentNode:'', position:{x:1025, y:2200}, data:{name:'Star Tracker Optical Heads 1',className:'stohContainer', value:{'Oh state':{'dbData':telemetryDictionary['St Oh1 Oh Stat'],'fname':''},'Expected Stars':{'dbData':telemetryDictionary['St Oh1 Expected Stars'],'fname':''}, "Coh Stars":{"dbData":telemetryDictionary['St Oh1 # Coh Stars'],'fname':''}}}},

    { id: 'IRU', type: 'sumBox6',parentNode:'', position:{x:25, y:2200}, data:{name:'Inertial Reference Unit (IRU)',className:'iruContainer', gryo1Used: telemetryDictionary['IRU Gryo1 data used'], gryo2Used: telemetryDictionary['IRU Gryo2 data used'], gryo3Used: telemetryDictionary['IRU Gryo3 data used'],value1:{'Pwr':{'dbData':telemetryDictionary['IRU Pwr'],'fname':''},'Gryo Bias Est':{'dbData':telemetryDictionary['IRU Gryo Est Status'],'fname':''}, "Sensor Good":{"dbData":telemetryDictionary['IRU Sensor Good'],'fname':''}, "Message Status":{"dbData":telemetryDictionary['IRU Message Status'],'fname':''}}}},

    { id: 'Thruster4', type: 'textbox',parentNode:'', position:{x:2125, y:1700}, data:{name:'Thruster 4', value:{'Fire':{'dbData':telemetryDictionary['Thruster 4 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 4 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 4 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 4 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 4 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 4 Cat Bed Temp'],'fname':''}}}},
    { id: 'Thruster3', type: 'textbox',parentNode:'', position:{x:1425, y:1700}, data:{name:'Thruster 3', value:{'Fire':{'dbData':telemetryDictionary['Thruster 3 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 3 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 3 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 3 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 3 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 3 Cat Bed Temp'],'fname':''}}}},
    { id: 'Thruster2', type: 'textbox',parentNode:'', position:{x:725, y:1700}, data:{name:'Thruster 2', value:{'Fire':{'dbData':telemetryDictionary['Thruster 2 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 2 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 2 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 2 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 2 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 2 Cat Bed Temp'],'fname':''}}}},
    { id: 'Thruster1', type: 'textbox',parentNode:'', position:{x:25, y:1700}, data:{name:'Thruster 1', value:{'Fire':{'dbData':telemetryDictionary['Thruster 1 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 1 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 1 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 1 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 1 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 1 Cat Bed Temp'],'fname':''}}}},

    { id: 'RW4', type: 'rw',parentNode:'', position:{x:2225, y:1250}, data:{name:'Reaction Wheel 4', power:telemetryDictionary['RW4 Pwr'], speed:telemetryDictionary['RW4 Speed/dir']}},
    { id: 'RW3', type: 'rw',parentNode:'', position:{x:2225, y:900}, data:{name:'Reaction Wheel 3', power:telemetryDictionary['RW3 Pwr'], speed:telemetryDictionary['RW3 Speed/dir']}},
    { id: 'RW2', type: 'rw',parentNode:'', position:{x:2225, y:550}, data:{name:'Reaction Wheel 2', power:telemetryDictionary['RW2 Pwr'], speed:telemetryDictionary['RW2 Speed/dir']}},
    { id: 'RW1', type: 'rw',parentNode:'', position:{x:2225, y:200}, data:{name:'Reaction Wheel 1', power:telemetryDictionary['RW1 Pwr'], speed:telemetryDictionary['RW1 Speed/dir']}},

    { id: 'SS12', type: 'sunSensor',parentNode:'', position:{x:375, y:1400}, data:{name:'Sun Sensor 12', 'Intensity':telemetryDictionary['SS12 Intensity'], 'DataUsed':telemetryDictionary['SS12 Data Used'],'fname':''}},
    { id: 'SS11', type: 'sunSensor',parentNode:'', position:{x:375, y:1150}, data:{name:'Sun Sensor 11', 'Intensity':telemetryDictionary['SS11 Intensity'], 'DataUsed':telemetryDictionary['SS11 Data Used'],'fname':''}},
    { id: 'SS10', type: 'sunSensor',parentNode:'', position:{x:375, y:900}, data:{name:'Sun Sensor 10', 'Intensity':telemetryDictionary['SS10 Intensity'], 'DataUsed':telemetryDictionary['SS10 Data Used'],'fname':''}},
    { id: 'SS9', type: 'sunSensor',parentNode:'', position:{x:375, y:650}, data:{name:'Sun Sensor 9', 'Intensity':telemetryDictionary['SS9 Intensity'], 'DataUsed':telemetryDictionary['SS9 Data Used'],'fname':''}},
    { id: 'SS8', type: 'sunSensor',parentNode:'', position:{x:375, y:400}, data:{name:'Sun Sensor 8', 'Intensity':telemetryDictionary['SS8 Intensity'], 'DataUsed':telemetryDictionary['SS8 Data Used'],'fname':''}},
    { id: 'SS7', type: 'sunSensor',parentNode:'', position:{x:375, y:150}, data:{name:'Sun Sensor 7', 'Intensity':telemetryDictionary['SS7 Intensity'], 'DataUsed':telemetryDictionary['SS7 Data Used'],'fname':''}},
 
    { id: 'SS6', type: 'sunSensor',parentNode:'', position:{x:25, y:1400}, data:{name:'Sun Sensor 6', 'Intensity':telemetryDictionary['SS6 Intensity'], 'DataUsed':telemetryDictionary['SS6 Data Used'],'fname':''}},
    { id: 'SS5', type: 'sunSensor',parentNode:'', position:{x:25, y:1150}, data:{name:'Sun Sensor 5', 'Intensity':telemetryDictionary['SS5 Intensity'], 'DataUsed':telemetryDictionary['SS5 Data Used'],'fname':''}},
    { id: 'SS4', type: 'sunSensor',parentNode:'', position:{x:25, y:900}, data:{name:'Sun Sensor 4', 'Intensity':telemetryDictionary['SS4 Intensity'], 'DataUsed':telemetryDictionary['SS4 Data Used'],'fname':''}},
    { id: 'SS3', type: 'sunSensor',parentNode:'', position:{x:25, y:650}, data:{name:'Sun Sensor 3', 'Intensity':telemetryDictionary['SS3 Intensity'], 'DataUsed':telemetryDictionary['SS3 Data Used'],'fname':''}},
    { id: 'SS2', type: 'sunSensor',parentNode:'', position:{x:25, y:400}, data:{name:'Sun Sensor 2', 'Intensity':telemetryDictionary['SS2 Intensity'], 'DataUsed':telemetryDictionary['SS2 Data Used'],'fname':''}},
    { id: 'SS1', type: 'sunSensor',parentNode:'', position:{x:25, y:150}, data:{name:'Sun Sensor 1', 'Intensity':telemetryDictionary['SS1 Intensity'], 'DataUsed':telemetryDictionary['SS1 Data Used'],'fname':''}},

    { id: 'InfoBox', type: 'vmodeBox', parentNode: '', position: { x: 25, y: -65 }, data: { name: '', playBackValue: { time: pbTime, loop: pbLoop }, 'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},
 
  ];

  return nodes;
};
