import React,{useState} from 'react'
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';
import { InnerData, TLM } from 'utils/type';
import { determineColorNew } from 'utils/function';

type VisibleTooltips = { [key: string]: boolean };

type SummaryBox3Props = {
    data: {
        name: string;
        value1: InnerData;
        value2: InnerData;
        target: string;
        totalSysX: TLM;
        totalSysY: TLM;
        totalSysZ: TLM;
    };
};

const SummaryBox3: React.FC<SummaryBox3Props> = ({data}) => {

  const calculateTotal = (TSX: TLM,TSY: TLM,TSZ: TLM) => {
    return Math.sqrt(Math.pow(parseFloat(TSX.telemetry),2) + Math.pow(parseFloat(TSY.telemetry),2) + Math.pow(parseFloat(TSZ.telemetry),2));
  }

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>

      <Handle type="target" position={Position.Right} id="target-right"/>

      <Handle type="target" position={Position.Bottom} id="target-bottom"/>

      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>

      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

      <div className={cx(scheme.textboxContainer, css`
      width:900px;
      font-size: 38px;
      `)}>

        <p>{data.name}</p>

        <div>
          <p>Momentum</p>
        </div>

        <div className={cx(css`
          display: flex;
          flex-direction: row;
          width: 900px;
          flex-wrap: wrap;
        `)}>
          
        
        {data.value2 && Object.entries(data.value2).map(([key, innerData]) => (
          <div className={cx(scheme.tbValueContainer, css`
          display: flex;
          flex-direction: column;
          width: 295px;
          `, 'nowheel')} key={key} >
            <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
              {key}:
              {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
              <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
            </div>
            <div className={cx(scheme.tbValueSpanContainer, css`
              width: 275px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)}>
              <span 
                className={cx(
                  scheme.value, 
                  css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:60px`)}>{innerData.dbData.unit}</span>}
            </div>
            {visibleLimitPops[key] && 
              <div className={scheme.chartContainer}>
                <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                  Close Plot
                </button>
              </div>}
          </div>
        ))}
      </div>
        
        <div className={cx(scheme.textboxItems, 'nowheel', css`margin-top: 20px`)}>

        <div className={scheme.tbValueContainer} >
              <div className={scheme.FnameKeyContainer}>
                G - Total Mom:
              </div>
              <div className={cx(scheme.tbValueSpanContainer, css`
              width: 320px;
              height: 55px;
              `)}>
                <span 
                  className={cx(
                    scheme.value,
                    css`line-height: 55px; color: #B86D12;`,
                  )}>
                  {calculateTotal(data.totalSysX,data.totalSysY,data.totalSysZ).toFixed(4)}
                </span>
              </div>
            </div>
        
          {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key} >
              <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={cx(scheme.tbValueSpanContainer, css`
              width: 320px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)} style={{ backgroundColor: innerData.dbData?.mne === 'gnc_env_est_output_eclipsestate_agg' ? 'rgb(28, 42, 59)' : 'white' }}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                    
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>

        
      </div>
    </div>
  )
}

export default SummaryBox3;

