import * as THREE from 'three';
import React, { useRef, useEffect } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three-stdlib';
import { TLM } from 'utils/type';

type ThreeSceneProps = {
  data: {
    qx: TLM;
    qy: TLM;
    qz: TLM;
    qs: TLM;
  };
};

const ThreeScene: React.FC<ThreeSceneProps> = ({ data }) => {
  const width = 1500;
  const height = 1250;
  const mountRef = useRef<HTMLDivElement>(null);
  const objectRef = useRef<THREE.Object3D | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const controlsRef = useRef<OrbitControls | null>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);

  useEffect(() => {
    if (!mountRef.current) {
      return;
    }

    // Scene setup
    const scene = new THREE.Scene();
    sceneRef.current = scene;
    const camera = new THREE.PerspectiveCamera(40, width / height, 0.1, 200);
    cameraRef.current = camera;
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    rendererRef.current = renderer;
    renderer.setSize(width, height);
    renderer.setClearColor(0x124313, 0);
    mountRef.current.appendChild(renderer.domElement);

    // OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controlsRef.current = controls;
    controls.enableDamping = true;

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff);
    scene.add(ambientLight);

    // Set up DracoLoader
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/'); // Path to Draco decoder

    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);

    // Lazy load the model
    const loadModel = async () => {
      loader.load(
        'public/plugins/ssl-carruthersacs-panel/img/glide_glb_test.glb', // Draco-compressed GLTF/GLB
        (gltf) => {
          const object = gltf.scene;
          object.position.set(0, 0, 0);
          object.scale.set(0.04, 0.04, 0.04);
          scene.add(object);

          // Store object reference
          objectRef.current = object;

          camera.position.set(0, 0, 16);
          controls.update();

          const origin = new THREE.Vector3(0, 0, 40);
          const length = 125;

          const sunDir = new THREE.Vector3(1, 2, 0).normalize();
          const sunArrowHelper = new THREE.ArrowHelper(sunDir, origin, length, 0xffff00);

          const earthDir = new THREE.Vector3(-1, 2, 0).normalize();
          const earthArrowHelper = new THREE.ArrowHelper(earthDir, origin, length, 0x00ffff);

          object.add(sunArrowHelper);
          object.add(earthArrowHelper);
        },
        undefined,
        (error) => {
          console.error('An error occurred:', error);
        }
      );
    };

    loadModel();
    
    
  }, []); // Empty dependency array to avoid re-initializing the scene

  useEffect(() => {

    const q = new THREE.Quaternion(parseFloat(data.qx.telemetry), parseFloat(data.qy.telemetry), parseFloat(data.qz.telemetry), parseFloat(data.qs.telemetry));
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      //Apply quaternion rotation to the object
      if (objectRef.current) {
          objectRef.current.setRotationFromQuaternion(q);
      }

      if (controlsRef.current){
        controlsRef.current.update(); 
        
        if (rendererRef.current && sceneRef.current && cameraRef.current){
          rendererRef.current.render(sceneRef.current, cameraRef.current);
        }
      }
    };

    animate();
  }, [data]);

  return <div ref={mountRef} />;
};

export default ThreeScene;
