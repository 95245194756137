import { css } from '@emotion/css';

export const stContainer = css`
width: 750px;
`;

export const iruContainer = css`
width: 750px;
`;


// Define a type for the class keys
export type ClassKey = 'stContainer' | 'iruContainer'

