import React, { useCallback, useState } from 'react';
import { css, cx, keyframes } from '@emotion/css';
import { InnerData, TLM, VisibleTooltips } from 'utils/type';
import * as scheme from './Scheme';
import { determineColorNew, handleCopyMneName } from 'utils/function';
import PlotlyChart from './PlotlyChart';

interface StarTrackerProp {
  data: {
    name: string;
    valueOh1: InnerData;
    valueOh2: InnerData;
    className: string;
    power: TLM;
    value1: InnerData
  };
}

const StarTracker: React.FC<StarTrackerProp> = ({ data }) => {

  // Add this helper function at the top of the file
const formatKeyDisplay = (key: string) => {
  if (key.includes('OH_STATE_')) {
    return key.replace('OH_STATE_', '');
  }
  return key;
};

  const [tooltipStates, setTooltipStates] = useState({
    tooltips: {} as VisibleTooltips,
    limitPops: {} as VisibleTooltips,
  });

  // Tooltip handlers
  const handleTooltipInteraction = useCallback(
    (type: 'tooltip' | 'limitPop', key?: string, duration: number = 2000) => {
      setTooltipStates((prev) => {
        const newState = { ...prev };

        if (key) {
          const stateKey = type === 'tooltip' ? 'tooltips' : 'limitPops';
          newState[stateKey] = {
            ...prev[stateKey],
            [key]: !prev[stateKey][key],
          };

          if (type === 'tooltip') {
            setTimeout(() => {
              setTooltipStates((current) => ({
                ...current,
                tooltips: {
                  ...current.tooltips,
                  [key]: false,
                },
              }));
            }, duration);
          }
        }
        return newState;
      });
    },
    []
  );

  const handleMneNameClick = useCallback(
    (key: string) => {
      handleTooltipInteraction('tooltip', key);
    },
    [handleTooltipInteraction]
  );

  const handleValueChartClick = useCallback(
    (key: string) => {
      handleTooltipInteraction('limitPop', key, 0);
    },
    [handleTooltipInteraction]
  );

  const powerOn = data.power?.telemetry === 'ON';
  
  return (
    <div className={
      css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 10px;
        align-items: center;
      `
    }>
    <div className={
      css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        row-gap: 10px;
        align-items: center;
      `
    }>
      <div className={scheme.textboxItems}>
        {data.valueOh1 &&
          Object.entries(data.valueOh1).map(([key, innerData]) => (
            <div className={cx(scheme.svgValueSpanContainer, css`width: 300px`)} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer, css`width:60%`)} onClick={() => handleMneNameClick(key)}>
                {key}
                {tooltipStates.tooltips[key] && (
                  <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                    {innerData.dbData.mne}
                  </span>
                )}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>
                  {innerData.fname}
                </span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`
                    color: ${innerData.dbData?.live || ''};
                  `,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {formatKeyDisplay(innerData.dbData?.telemetry)}
              </span>
              {innerData.dbData?.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {tooltipStates.limitPops[key] && (
                <div className={scheme.chartContainer}>
                  <PlotlyChart
                    data={{
                      mne: innerData.dbData.mne,
                      sc: innerData.dbData.spacecraft,
                    }}
                  />
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
      <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="550px" height="350px" viewBox="-0.5 -0.5 550 350">
  <path d="M160 50 L400 50 L400 150 L160 150 Z" fill={powerOn ? 'green' : '#888'} />
  {/* <!-- Top face --> */}
  <path d="M160 50 L400 50 L387 17 L147 17 Z" fill={powerOn ? 'green' : '#aaa'} />
  {/* <!-- Left face --> */}
  <path d="M147 117 L160 150 L160 50 L147 17 Z" fill={powerOn ? 'green' : '#888'} />
  {/* <!-- Left Optical Cone --> */}
  <g transform="translate(253 100) rotate(45)">
    <path d="M0 0 L-67 267 L67 267 Z" fill="#999" stroke="#666" stroke-width="2" />
    <ellipse cx="0" cy="267" rx="67" ry="20" fill="#666" />
  </g>
  
  <g transform="translate(307 100) rotate(-45)">
    <path d="M0 0 L-67 267 L67 267 Z" fill="#999" stroke="#666" stroke-width="2" />
    <ellipse cx="0" cy="267" rx="67" ry="20" fill="#666" />
  </g>
</svg>

<p>{data.name}</p>
      </div>

      <div className={scheme.textboxItems}>
        {data.valueOh2 &&
          Object.entries(data.valueOh2).map(([key, innerData]) => (
            <div className={cx(scheme.svgValueSpanContainer, css`width: 300px`)} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer, css`width:60%`)} onClick={() => handleMneNameClick(key)}>
                {key}
                {tooltipStates.tooltips[key] && (
                  <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                    {innerData.dbData.mne}
                  </span>
                )}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>
                  {innerData.fname}
                </span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`
                    color: ${innerData.dbData?.live || ''};
                  `,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {formatKeyDisplay(innerData.dbData?.telemetry)}
              </span>
              {innerData.dbData?.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {tooltipStates.limitPops[key] && (
                <div className={scheme.chartContainer}>
                  <PlotlyChart
                    data={{
                      mne: innerData.dbData.mne,
                      sc: innerData.dbData.spacecraft,
                    }}
                  />
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
    <div className={cx(scheme.textboxItems, css`
    flex-direction: row;
    justify-content: center;
    width: 1000px;
    flex-wrap: wrap;
    gap: 20px;
    `)}>
    {data.value1 &&
      Object.entries(data.value1).map(([key, innerData]) => (
        <div className={cx(scheme.svgValueSpanContainer, css`width: 300px`)} key={key}>
          <span className={cx(scheme.svgKey, scheme.FnameKeyContainer, css`width:60%`)} onClick={() => handleMneNameClick(key)}>
            {key}
            {tooltipStates.tooltips[key] && (
              <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                {innerData.dbData.mne}
              </span>
            )}
            <span data-comp="fname" className={scheme.svgFnameTooltip}>
              {innerData.fname}
            </span>
          </span> 
          <span
            onClick={() => handleValueChartClick(key)}
            className={cx(
              scheme.svgValue,
              css`
                color: ${innerData.dbData?.live || ''};
              `,
              scheme.classMap[determineColorNew(innerData.dbData?.limit)]
            )}
          > 
            {innerData.dbData?.telemetry}
          </span>
          {innerData.dbData?.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
          {tooltipStates.limitPops[key] && (
            <div className={scheme.chartContainer}>
              <PlotlyChart
                data={{
                  mne: innerData.dbData.mne,
                  sc: innerData.dbData.spacecraft,
                }}
              />
              <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                Close Plot
              </button>
            </div>
          )}
        </div>
      ))}
  </div>
  </div>
  );
};

export default StarTracker;
