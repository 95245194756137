import React, { useEffect, useState } from 'react';
import { css, keyframes } from '@emotion/css';
import { InnerData, TLM } from 'utils/type';

interface ReactionWheelProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
    power: TLM;
    speed: TLM;
  };
}

const generateRotateKeyframes = (rotationAngle: number) => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(${rotationAngle}deg);
  }
`;

const ReactionWheel: React.FC<ReactionWheelProp> = ({ data }) => {
  const rotationSpeed = Math.abs(parseFloat(data.speed?.telemetry) || 0);
  const rotationDirection = (parseFloat(data.speed?.telemetry) || 0) >= 0 ? 1 : -1;
  const rotationAngle = rotationDirection * 360;
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    const currentValue = data.speed && data.speed.telemetry ? parseFloat(data.speed.telemetry) : null;
    if (!currentValue) {
      return
    }

    setCurrentValue(currentValue);

  }, [data.speed]);

  const rotationAnimation = generateRotateKeyframes(rotationAngle);

  const powerOn = data.power?.telemetry === 'ON';

  const wheelStyles = css`
    .wheel-group {
      transform-origin: 75px 75px;
      animation: ${rotationSpeed > 0 && powerOn ? `${rotationAnimation} 2s linear infinite` : 'none'};
    }
  `;

  return (
    <div>
    <div
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200px"
        height="200px"
        viewBox="-0.5 -0.5 200 200"
        className={wheelStyles}
      >
        <circle cx="100" cy="100" r="90" fill="#E0E0E0" stroke="#333" strokeWidth="3" />
        <circle cx="100" cy="100" r="70" fill="#C0C0C0" stroke="#000" strokeWidth="2" />

        <g className="wheel-group">
          {/* Rotation axis */}
          <line x1="100" y1="10" x2="100" y2="190" stroke="#444" strokeWidth="4" />

          {/* Mounting points */}
          <circle cx="100" cy="20" r="10" fill="#666" stroke="#000" strokeWidth="2" />
          <circle cx="100" cy="180" r="10" fill="#666" stroke="#000" strokeWidth="2" />
        </g>

        {/* Directional arrows */}
        {/* - arrow */}
        <line
          x1="50"
          y1="100"
          x2="80"
          y2="100"
          stroke={rotationDirection < 0 && powerOn? "#009900" : "#444"} /* Green if negative */
          strokeWidth="4"
        />
        {/* + arrow */}
        <line
          x1="120"
          y1="100"
          x2="150"
          y2="100"
          stroke={rotationDirection > 0 && powerOn ? "#009900" : "#444"} /* Green if positive */
          strokeWidth="4"
        />
        <line
          x1="135"
          y1="85"
          x2="135"
          y2="115"
          stroke={rotationDirection > 0 && powerOn ? "#009900" : "#444"} /* Green if positive */
          strokeWidth="4"
        />
      <text x="100" y="135" fontSize="25" textAnchor="middle" fill="black">{currentValue}</text>
      </svg>
    </div>
    <p>{data.name}</p>
    </div>
  );
};

export default ReactionWheel;
