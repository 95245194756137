import React,{useState} from 'react'
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles from Scheme
import { css, cx } from '@emotion/css';
import Time3dFacade from './TimeFacade';
import * as sumboxScheme from './SumBoxScheme';
import { InnerData, TLM, VisibleTooltips } from 'utils/type';
import { determineColorNew, handleCopyMneName } from 'utils/function';

type SummaryBox1Props = {
    data: {
        name: string;
        value1: InnerData;
        value2: InnerData;
        target: string;
        className: string;

    };
};

const SummaryBox1: React.FC<SummaryBox1Props> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  //console.log('ddasda', data);

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>

      <Handle type="target" position={Position.Right} id="target-right"/>

      <Handle type="target" position={Position.Bottom} id="target-bottom"/>

      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>

      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

      <div className={cx(scheme.textboxContainer, css`
      width:900px;
      font-size: 38px;
      `)}>

        <p>{data.name}</p>

        <div>
            <p>Next Maneuver</p>
            <div>
                <Time3dFacade data={{name: 'Time Remaining', targetDate: '2024-12-20T23:00:00Z'}}></Time3dFacade>
            </div>
        </div>

        <div className={`${scheme.textboxItems} nowheel`}>
        
          {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key} >
              <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={cx(scheme.tbValueSpanContainer, css`
              width: 320px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)} style={{ backgroundColor: innerData.dbData?.mne === 'gnc_env_est_output_eclipsestate_agg' ? 'rgb(28, 42, 59)' : 'white' }}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                    
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit,css`line-height:55px;`)}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>

        <div className={cx(css`
          display: flex;
          flex-direction: row;
          width: 900px;
          flex-wrap: wrap;
        `)}>
        
        {data.value2 && Object.entries(data.value2).map(([key, innerData]) => (
          <div className={cx(scheme.tbValueContainer, css`
          display: flex;
          flex-direction: column;
          width: 295px;
          `, 'nowheel')} key={key} >
            <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
              {key}:
              {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
              <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
            </div>
            <div className={cx(scheme.tbValueSpanContainer, css`
              width: 275px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)}>
              <span 
                className={cx(
                  scheme.value, 
                  css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:60px`)}>{innerData.dbData.unit}</span>}
            </div>
            {visibleLimitPops[key] && 
              <div className={scheme.chartContainer}>
                <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                  Close Plot
                </button>
              </div>}
          </div>
        ))}
      </div>
      </div>
    </div>
  )
}

const SummaryBox2: React.FC<SummaryBox1Props> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>

      <Handle type="target" position={Position.Right} id="target-right"/>

      <Handle type="target" position={Position.Bottom} id="target-bottom"/>

      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>

      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

      <div className={cx(scheme.textboxContainer, css` 
      width:900px;
      font-size: 38px;`)}>

        <p>Ephermeris Summary</p>

        <div>
            <p>Active Ephemeris File</p>
            <p></p>
        </div>

        <div>
          <p>Spacecraft Ephemeris</p>
          <div className={css`
          display: flex;
          flex-direction: row;
          width: 900px;
          flex-wrap: wrap;
        `}>
          
            {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
              <div className={cx(scheme.tbValueContainer, css`
              display: flex;
              flex-direction: column;
              width: 295px;
              `)} key={key} >
                <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                  {key}:
                  {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                  <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
                </div>
                <div className={cx(scheme.tbValueSpanContainer, css`
              width: 275px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)}>
                  <span 
                    className={cx(
                      scheme.value, 
                      css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                    )}
                  >
                    {innerData.dbData?.telemetry}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:55px`)}>{innerData.dbData.unit}</span>}
                </div>
                {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>}
              </div>
            ))}
          </div>
        </div>

        <div>
          <p>Delta Ephemeris</p>
          <div className={css`
          display: flex;
          flex-direction: row;
          width: 900px;
          flex-wrap: wrap;
        `}>
            {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
              <div className={cx(scheme.tbValueContainer, css`
              display: flex;
              flex-direction: column;
              width: 295px;
              `)} key={key} >
                <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                  {key}:
                  <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
                </div>
                <div className={cx(scheme.tbValueSpanContainer, css`
              width: 275px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)}>
                  <span 
                    className={cx(
                      scheme.value, 
                      css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                    )}
                  >
                    {parseFloat(innerData.dbData?.telemetry) * 0}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:55px`)}>{innerData.dbData.unit}</span>}
                </div>
                {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>}
              </div>
            ))}
          </div>
      </div>
      </div>
    </div>
  )
}

type SummaryBox4Props = {
  data: {
      name: string;
      value1: InnerData;
      value2: InnerData;
      target: string;
      className: string;
      catBedPri: TLM;
      catBedRed: TLM;
      Thruster12Arm: TLM;
      Thruster34Arm: TLM;
  };
};

const SummaryBox4: React.FC<SummaryBox4Props> = ({data}) => {

const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

const handleMneNameClick = (key: string) => {
  setVisibleTooltips(prevVisibleTooltips => ({
    ...prevVisibleTooltips,
    [key]: true
  }));

  setTimeout(() => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: false
    }));
  }, 5000);
};

const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


const handleValueChartClick = (key: string) => {
  setVisibleLimitPops(prevVisibleLimitPops => ({
    ...prevVisibleLimitPops,
    [key]: !prevVisibleLimitPops[key]
  }));
};

const svgContainer = css`
display: flex;
justify-content: space-between;
padding: 0px 10px; 
height: 55px;
`;

const svgInnerContainer = css`
display: flex;
`;

const getColor = (self: string) => {
  if (!self){
    return "rgb(100,100,100)";
  }

  if (self.toLowerCase() === 'on'){
      return "rgb(72, 200, 44)";
  }
  else{
    return "rgb(100,100,100)";
  }
}

return (
  <div>
    <Handle type="target" position={Position.Top} id="target-top"/>
    <Handle type="target" position={Position.Left} id="target-left"/>

    <Handle type="target" position={Position.Right} id="target-right"/>

    <Handle type="target" position={Position.Bottom} id="target-bottom"/>

    <Handle type="source" position={Position.Top} id="source-top"/>
    <Handle type="source" position={Position.Left} id="source-left"/>

    <Handle type="source" position={Position.Right} id="source-right"/>
    <Handle type="source" position={Position.Bottom} id="source-bottom"/>

    <div className={cx(scheme.textboxContainer, css`
    width:900px;
    font-size: 38px;
    `, sumboxScheme[data.className as sumboxScheme.ClassKey])}>

      <p>{data.name}</p>

      <div className={cx(css`
        display: flex;
        flex-direction: row;
        width: 900px;
        flex-wrap: wrap;
      `)}>
        
      
      {data.value2 && Object.entries(data.value2).map(([key, innerData]) => (
        <div className={cx(scheme.tbValueContainer, css`
        display: flex;
        flex-direction: column;
        width: 295px;
        `, 'nowheel')} key={key} >
          <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
            {key}:
            {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
            <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
          </div>
          <div className={cx(scheme.tbValueSpanContainer, css`
            width: 275px;
            height: 55px;
            `)} onClick={() => handleValueChartClick(key)}>
            <span 
              className={cx(
                scheme.value, 
                css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
              )}
            >
              {innerData.dbData?.telemetry}
            </span>
            {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:60px`)}>{innerData.dbData.unit}</span>}
          </div>
          {visibleLimitPops[key] && 
            <div className={scheme.chartContainer}>
              <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
              <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                Close Plot
              </button>
            </div>}
        </div>
      ))}
    </div>
      
      <div className={`${scheme.textboxItems} nowheel`}>
      
        {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
          <div className={scheme.tbValueContainer} key={key} >
            <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
              {key}:
              {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
              <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
            </div>
            <div className={cx(scheme.tbValueSpanContainer, css`
            width: 320px;
            height: 55px;
            `)} onClick={() => handleValueChartClick(key)} style={{ backgroundColor: innerData.dbData?.mne === 'gnc_env_est_output_eclipsestate_agg' ? 'rgb(28, 42, 59)' : 'white' }}>
              <span 
                className={cx(
                  scheme.value, 
                  css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                  
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
            </div>
            {visibleLimitPops[key] && 
              <div className={scheme.chartContainer}>
                <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                  Close Plot
                </button>
              </div>}
          </div>
        ))}
      <div className={svgContainer}>

<p>Cat Bed Htr Power:</p>

<div className={svgInnerContainer}>

{data.catBedPri && <div>
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="170px" height="55px" viewBox="-0.5 -0.5 170 55">
  <g>
    <g>
    <rect x="5" y="5" width="160" height="45" fill="rgb(38,38,38)" stroke={getColor(data.catBedPri.telemetry)} pointerEvents="all" strokeWidth="6"/>
    </g>
    <g>
      <text x="80" y="40" fill={getColor(data.catBedPri.telemetry)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">Pri
      </text>
    </g>
  </g>
</svg>
    </div>}

    {data.catBedRed && <div>
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="170px" height="55px" viewBox="-0.5 -0.5 170 55">
  <g>
    <g>
    <rect x="5" y="5" width="160" height="45" fill="rgb(38,38,38)" stroke={getColor(data.catBedRed.telemetry)} pointerEvents="all" strokeWidth="6"/>
    </g>
    <g>
      <text x="80" y="40" fill={getColor(data.catBedRed.telemetry)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">Red
      </text>
    </g>
  </g>
</svg>
    </div>}
    </div>
    </div>
        <div className={svgContainer}>

        <p>Thruster Arm Status:</p>

        <div className={svgInnerContainer}>

        {data.Thruster12Arm && <div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="170px" height="55px" viewBox="-0.5 -0.5 170 55">
            <g>
              <g>
              <rect x="5" y="5" width="160" height="45" fill="rgb(38,38,38)" stroke={getColor(data.Thruster12Arm.telemetry)} pointerEvents="all" strokeWidth="6"/>
              </g>
              <g>
                <text x="80" y="40" fill={getColor(data.Thruster12Arm.telemetry)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">1 & 2
                </text>
              </g>
            </g>
          </svg>
              </div>}

              {data.Thruster34Arm && <div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="170px" height="55px" viewBox="-0.5 -0.5 170 55">
            <g>
              <g>
              <rect x="5" y="5" width="160" height="45" fill="rgb(38,38,38)" stroke={getColor(data.Thruster34Arm.telemetry)} pointerEvents="all" strokeWidth="6"/>
              </g>
              <g>
                <text x="80" y="40" fill={getColor(data.Thruster34Arm.telemetry)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">3 & 4
                </text>
              </g>
            </g>
          </svg>
            </div>}
          </div>
        </div>
      </div>
      
    </div>
  </div>
)
}

const SummaryBox5: React.FC<SummaryBox1Props> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>

      <Handle type="target" position={Position.Right} id="target-right"/>

      <Handle type="target" position={Position.Bottom} id="target-bottom"/>

      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>

      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

      <div className={cx(scheme.textboxContainer, css`
      width:900px;
      font-size: 38px;
      `, sumboxScheme[data.className as sumboxScheme.ClassKey])}>

        <p>{data.name}</p>

        <div className={cx(css`
          display: flex;
          flex-direction: row;
          width: 900px;
          flex-wrap: wrap;
        `)}>
          
        
        {data.value2 && Object.entries(data.value2).map(([key, innerData]) => (
          <div className={cx(scheme.tbValueContainer, css`
          display: flex;
          flex-direction: column;
          width: 295px;
          `, 'nowheel')} key={key} >
            <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
              {key}:
              {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
              <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
            </div>
            <div className={cx(scheme.tbValueSpanContainer, css`
              width: 275px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)}>
              <span 
                className={cx(
                  scheme.value, 
                  css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:60px`)}>{innerData.dbData.unit}</span>}
            </div>
            {visibleLimitPops[key] && 
              <div className={scheme.chartContainer}>
                <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                  Close Plot
                </button>
              </div>}
          </div>
        ))}
      </div>
        
        <div className={`${scheme.textboxItems} nowheel`}>
        
          {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key} >
              <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={cx(scheme.tbValueSpanContainer, css`
              width: 320px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)} style={{ backgroundColor: innerData.dbData?.mne === 'gnc_env_est_output_eclipsestate_agg' ? 'rgb(28, 42, 59)' : 'white' }}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                    
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}

      </div>
      </div>
    </div>
  )
}

type SummaryBox6Props = {
  data: {
      name: string;
      value1: InnerData;
      value2: InnerData;
      target: string;
      className: string;
      gryo1Used: TLM;
      gryo2Used: TLM;
      gryo3Used: TLM;
      gryoBias: TLM;
      sensorGood: TLM;
  };
};

const SummaryBox6: React.FC<SummaryBox6Props> = ({data}) => {

const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

const handleMneNameClick = (key: string) => {
  setVisibleTooltips(prevVisibleTooltips => ({
    ...prevVisibleTooltips,
    [key]: true
  }));

  setTimeout(() => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: false
    }));
  }, 5000);
};

const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


const handleValueChartClick = (key: string) => {
  setVisibleLimitPops(prevVisibleLimitPops => ({
    ...prevVisibleLimitPops,
    [key]: !prevVisibleLimitPops[key]
  }));
};

const svgContainer = css`
display: flex;
justify-content: space-between;
padding: 0px 10px; 
height: 55px;
`;

const svgInnerContainer = css`
display: flex;
`;

const getColorUsed = (self: TLM) => {
  if (!self || !self.telemetry){
    return "rgb(100,100,100)";
  }

  if (self.telemetry.toLowerCase() === 'USED'.toLowerCase()){
      return "rgb(72, 200, 44)";
  }
  else{
    return "rgb(100,100,100)";
  }
}

const getColorEnable = (self: string) => {
  if (!self){
    return "rgb(100,100,100)";
  }

  if (self.toLowerCase() === 'ENBL'.toLowerCase()){
      return "rgb(72, 200, 44)";
  }
  else{
    return "rgb(100,100,100)";
  }
}

return (
  <div>
    <Handle type="target" position={Position.Top} id="target-top"/>
    <Handle type="target" position={Position.Left} id="target-left"/>

    <Handle type="target" position={Position.Right} id="target-right"/>

    <Handle type="target" position={Position.Bottom} id="target-bottom"/>

    <Handle type="source" position={Position.Top} id="source-top"/>
    <Handle type="source" position={Position.Left} id="source-left"/>

    <Handle type="source" position={Position.Right} id="source-right"/>
    <Handle type="source" position={Position.Bottom} id="source-bottom"/>

    <div className={cx(scheme.textboxContainer, css`
    width:900px;
    font-size: 38px;
    `, sumboxScheme[data.className as sumboxScheme.ClassKey])}>

      <p>{data.name}</p>

      <div className={cx(css`
        display: flex;
        flex-direction: row;
        width: 900px;
        flex-wrap: wrap;
      `)}>
        
      
      {data.value2 && Object.entries(data.value2).map(([key, innerData]) => (
        <div className={cx(scheme.tbValueContainer, css`
        display: flex;
        flex-direction: column;
        width: 295px;
        `, 'nowheel')} key={key} >
          <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
            {key}:
            {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
            <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
          </div>
          <div className={cx(scheme.tbValueSpanContainer, css`
            width: 275px;
            height: 55px;
            `)} onClick={() => handleValueChartClick(key)}>
            <span 
              className={cx(
                scheme.value, 
                css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
              )}
            >
              {innerData.dbData?.telemetry}
            </span>
            {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:60px`)}>{innerData.dbData.unit}</span>}
          </div>
          {visibleLimitPops[key] && 
            <div className={scheme.chartContainer}>
              <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
              <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                Close Plot
              </button>
            </div>}
        </div>
      ))}
    </div>
      
      <div className={`${scheme.textboxItems} nowheel`}>
      
        {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
          <div className={scheme.tbValueContainer} key={key} >
            <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
              {key}:
              {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
              <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
            </div>
            <div className={cx(scheme.tbValueSpanContainer, css`
            width: 320px;
            height: 55px;
            `)} onClick={() => handleValueChartClick(key)} style={{ backgroundColor: innerData.dbData?.mne === 'gnc_env_est_output_eclipsestate_agg' ? 'rgb(28, 42, 59)' : 'white' }}>
              <span 
                className={cx(
                  scheme.value, 
                  css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                  
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
            </div>
            {visibleLimitPops[key] && 
              <div className={scheme.chartContainer}>
                <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                  Close Plot
                </button>
              </div>}
          </div>
        ))}
      <div className={svgContainer}>

<p>Data Used:</p>

<div className={svgInnerContainer}>

<div>
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="55px" viewBox="-0.5 -0.5 115 55">
  <g>
    <g>
    <rect x="5" y="5" width="108" height="45" fill="rgb(38,38,38)" stroke={getColorUsed(data.gryo1Used)} pointerEvents="all" strokeWidth="6"/>
    </g>
    <g>
      <text x="60" y="40" fill={getColorUsed(data.gryo1Used)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">Gryo1
      </text>
    </g>
  </g>
</svg>
    </div>

    <div>
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="55px" viewBox="-0.5 -0.5 115 55">
  <g>
    <g>
    <rect x="5" y="5" width="108" height="45" fill="rgb(38,38,38)" stroke={getColorUsed(data.gryo2Used)} pointerEvents="all" strokeWidth="6"/>
    </g>
    <g>
      <text x="60" y="40" fill={getColorUsed(data.gryo2Used)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">Gryo2
      </text>
    </g>
  </g>
</svg> 
    </div>

    <div>
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="115px" height="55px" viewBox="-0.5 -0.5 115 55">
  <g>
    <g>
    <rect x="5" y="5" width="108" height="45" fill="rgb(38,38,38)" stroke={getColorUsed(data.gryo3Used)} pointerEvents="all" strokeWidth="6"/>
    </g>
    <g>
      <text x="60" y="40" fill={getColorUsed(data.gryo3Used)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">Gryo3
      </text>
    </g>
  </g>
</svg>
    </div>
    </div>
    </div>
      </div>
      
    </div>
  </div>
)
}

export {SummaryBox1, SummaryBox2, SummaryBox4,SummaryBox5, SummaryBox6};

