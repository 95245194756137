import React, { useEffect, useState } from 'react'
import { Handle, Position } from 'reactflow';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import { InnerData, TLM } from 'utils/type';

interface SunSensorProp{
  data: {
    name: string;
    value: InnerData;
    className: string;
    Intensity: TLM;
    DataUsed: TLM;

  }
}

type MyObjectType = {
  [key: string]: { calA: number; calB: number };
};

const dataChart: MyObjectType = {
  "1" : {
    calA: 1749.97,
    calB: 1864.92,
  },
  "2" : {
    calA: 1749.77,
    calB: 1868.74,
  },
  "3" : {
    calA: 1792.41,
    calB: 1868.74,
  },
  "4" : {
    calA: 1801.46,
    calB: 1862.82,
  },
  "5" : {
    calA: 1815.41,
    calB: 1862.34,
  },
  "6" : {
    calA: 1799.34,
    calB: 1867.34,
  },
  "7" : {
    calA: 1795.67,
    calB: 1867.12,
  },
  "8" : {
    calA: 1752.81,
    calB: 1869.48,
  },
  "9" : {
    calA: 1776,
    calB: 1864.55,
  },
  "10" : {
    calA: 1788.78,
    calB: 1868.24,
  },
  "11" : {
    calA: 1740.72,
    calB: 1873.87,
  },
  "12" : {
    calA: 1778.98,
    calB: 1861.22,
  },

  
}

const SunSensor: React.FC<SunSensorProp> = ({data}) => {

  const number = data.name.split(' ')[2];

  const circleFill = data.DataUsed && data.DataUsed.telemetry === 'NOT_USED' ? 'rgb(70,70,70)' : 'rgb(6,97,0)';

  // Calculate angle for line rotation based on data.Intensity.telemetry
  const [currentValue, setCurrentValue] = useState(0);
  const [sunAngle, setSunAngle] = useState(0);

  useEffect(() => {
    const currentValue = data.Intensity && data.Intensity.telemetry ? parseFloat(data.Intensity.telemetry) : null;
    if (!currentValue) {
      return
    }

    setCurrentValue(currentValue);

// Calculate sun angle in degrees for the line position
const calSunAngle = Math.acos((currentValue - dataChart[number].calB) / dataChart[number].calA) * (180 / Math.PI);
setSunAngle(calSunAngle);

  }, [data.Intensity]);

  const outerCircleColor = (sunDeg: number) => {
    // Define the range for dimming effect, e.g., from -90 to 90 degrees
    const maxDeg = 90;
    
    // Calculate the normalized distance from 0 (ranges from 0 to 1)
    const distance = Math.min(Math.abs(sunDeg), maxDeg) / maxDeg;
    
    // Set minimum brightness level to 30% and maximum to 100%
    const minBrightness = 0.6;
    const brightness = 1 - distance * (1 - minBrightness); // Ranges from 1 to 0.3
    
    // Calculate RGB values based on brightness
    const red = Math.round(255 * brightness);
    const green = Math.round(255 * brightness);
    
    // Return the color as a hex string
    return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}00`;
  };
  
  

  return (
    <div>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, left:50, top:20}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:85, left:85}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:85, right:85}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom-1" style={{opacity:0, left:50, bottom:30}}/>


        <div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="200px" height="100px" viewBox="-0.5 -0.5 200 100">
            <defs/>
  {/* <path d="M 30 100 A 70 70 0 0 1 170 100" fill="none" stroke="green" strokeWidth="10" />
  <path d="M 100 30 A 70 70 0 0 1 170 100" fill="none" stroke="yellow" strokeWidth="10" />
  
  <text x="0" y="105" fontSize="15" fill='white'>0</text>
  <text x="15" y="40" fontSize="15" fill='white'>750</text>
  <text x="85" y="20" fontSize="15" fill='white'>1500</text>
  <text x="155" y="60" fontSize="15" fill='white'>2250</text>
  <text x="155" y="105" fontSize="15" fill='white'>3000</text> */}
  
  <circle cx="100" cy="50" r="48" fill={circleFill} stroke={outerCircleColor(sunAngle)} strokeWidth="7" />
  <text x="100" y="55" fontSize="35" textAnchor="middle" fill={data.Intensity.live}>{currentValue}</text>
  
{/* Dynamic line based on intensity */}
<line
            x1="100"
            y1="50"
            x2="100"
            y2="0"
            stroke="white"
            strokeWidth="6"
            transform={`rotate(${isNaN(sunAngle) ? 180 : sunAngle}, 100, 50)`}
          />
          </svg>  
        </div>
        <p>{data.name}</p>
        
        <div className={scheme.svgValueSpanContainer}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}>
                G-A
              </span>
              <span  
                className={cx(
                  scheme.svgValue, 
                  css`color: #B86D12`,
                )}
              >
                {sunAngle.toFixed(2)}
              </span>
              <span className={scheme.svgUnit}>{'\u00B0C'}</span>
            </div>
    </div>
  );
}

export default SunSensor;
