import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, ProcessedData, TLM, TelemetryData } from './utils';
import Panel from '../module/Panel';
import Textbox from './Textbox';
import CustomEdge from './CustomEdge';
import SpacecraftModel from './SpacecraftModel';
import Chart from './PlotlyChart'
import { css } from '@emotion/css';
import HTextBox from './HTextbox';
import {SummaryBox1,SummaryBox2, SummaryBox4,SummaryBox5} from './SummaryBox1';
import SummaryBox3 from './SummaryBox3';
import { useTimeRangeContext } from 'hooks/TimeRangeContext';
import VModeBox from './VModeBox';
import { useTelemetryData } from '../hooks/DataProcessing';
import SunSensor from './SunSensor';

const reactAppWrapper = css` 

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-escCommPanel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  influxData: ProcessedData;
  limitData: Field[];
  // ... other props if there are ay
};

const nodeTypes = {spacecraftModel: SpacecraftModel, sunSensor: SunSensor,vmodeBox:VModeBox ,sumBox1:SummaryBox1,sumBox2:SummaryBox2,sumBox3:SummaryBox3,sumBox4:SummaryBox4,sumBox5:SummaryBox5,htextbox:HTextBox,chart: Chart,panel: Panel, textbox: Textbox}
const edgeTypes = {customEdge: CustomEdge}

const App: React.FC<AppProps> = ({dbData, source, width, height, influxData, limitData}) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);
  const { createTelemetryDictionary } = useTelemetryData(dbData, limitData);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      //@ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:575, y:150}, data:{'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},

    { id: 'St Oh1', type: 'textbox',parentNode:'', position:{x:1025, y:2400}, data:{name:'Star Tracker Optical Heads 1',className:'stohContainer', value:{'Oh state':{'dbData':telemetryDictionary['St Oh1 Oh Stat'],'fname':''},'Expected Stars':{'dbData':telemetryDictionary['St Oh1 Expected Stars'],'fname':''}, "Coh Stars":{"dbData":telemetryDictionary['St Oh1 # Coh Stars'],'fname':''}}}},
    { id: 'St Oh2', type: 'textbox',parentNode:'', position:{x:1025, y:2750}, data:{name:'Star Tracker Optical Heads 2',className:'stohContainer', value:{'Oh state':{'dbData':telemetryDictionary['St Oh1 Oh Stat'],'fname':''},'Expected Stars':{'dbData':telemetryDictionary['St Oh1 Expected Stars'],'fname':''}, "Coh Stars":{"dbData":telemetryDictionary['St Oh1 # Coh Stars'],'fname':''}}}},

    { id: 'SS1', type: 'sunSensor',parentNode:'', position:{x:25, y:150}, data:{name:'Sun Sensor 1', 'Intensity':telemetryDictionary['SS1 Intensity'], 'DataUsed':telemetryDictionary['SS1 Data Used'],'fname':''}},
    { id: 'SS2', type: 'sunSensor',parentNode:'', position:{x:25, y:400}, data:{name:'Sun Sensor 2', 'Intensity':telemetryDictionary['SS2 Intensity'], 'DataUsed':telemetryDictionary['SS2 Data Used'],'fname':''}},
    { id: 'SS3', type: 'sunSensor',parentNode:'', position:{x:25, y:650}, data:{name:'Sun Sensor 3', 'Intensity':telemetryDictionary['SS3 Intensity'], 'DataUsed':telemetryDictionary['SS3 Data Used'],'fname':''}},
    { id: 'SS4', type: 'sunSensor',parentNode:'', position:{x:25, y:900}, data:{name:'Sun Sensor 4', 'Intensity':telemetryDictionary['SS4 Intensity'], 'DataUsed':telemetryDictionary['SS4 Data Used'],'fname':''}},
    { id: 'SS5', type: 'sunSensor',parentNode:'', position:{x:25, y:1150}, data:{name:'Sun Sensor 5', 'Intensity':telemetryDictionary['SS5 Intensity'], 'DataUsed':telemetryDictionary['SS5 Data Used'],'fname':''}},
    { id: 'SS6', type: 'sunSensor',parentNode:'', position:{x:25, y:1400}, data:{name:'Sun Sensor 6', 'Intensity':telemetryDictionary['SS6 Intensity'], 'DataUsed':telemetryDictionary['SS6 Data Used'],'fname':''}},

    { id: 'SS7', type: 'sunSensor',parentNode:'', position:{x:2175, y:150}, data:{name:'Sun Sensor 7', 'Intensity':telemetryDictionary['SS7 Intensity'], 'DataUsed':telemetryDictionary['SS7 Data Used'],'fname':''}},
    { id: 'SS8', type: 'sunSensor',parentNode:'', position:{x:2175, y:400}, data:{name:'Sun Sensor 8', 'Intensity':telemetryDictionary['SS8 Intensity'], 'DataUsed':telemetryDictionary['SS8 Data Used'],'fname':''}},
    { id: 'SS9', type: 'sunSensor',parentNode:'', position:{x:2175, y:650}, data:{name:'Sun Sensor 9', 'Intensity':telemetryDictionary['SS9 Intensity'], 'DataUsed':telemetryDictionary['SS9 Data Used'],'fname':''}},
    { id: 'SS10', type: 'sunSensor',parentNode:'', position:{x:2175, y:900}, data:{name:'Sun Sensor 10', 'Intensity':telemetryDictionary['SS10 Intensity'], 'DataUsed':telemetryDictionary['SS10 Data Used'],'fname':''}},
    { id: 'SS11', type: 'sunSensor',parentNode:'', position:{x:2175, y:1150}, data:{name:'Sun Sensor 11', 'Intensity':telemetryDictionary['SS11 Intensity'], 'DataUsed':telemetryDictionary['SS11 Data Used'],'fname':''}},
    { id: 'SS12', type: 'sunSensor',parentNode:'', position:{x:2175, y:1400}, data:{name:'Sun Sensor 12', 'Intensity':telemetryDictionary['SS12 Intensity'], 'DataUsed':telemetryDictionary['SS12 Data Used'],'fname':''}},
 
    { id: 'RW1', type: 'textbox',parentNode:'', position:{x:25, y:1650}, data:{name:'Reaction Wheel 1', value:{'Power':{'dbData':telemetryDictionary['RW1 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW1 Speed/dir'],'fname':''}}}},
    { id: 'RW2', type: 'textbox',parentNode:'', position:{x:725, y:1650}, data:{name:'Reaction Wheel 2', value:{'Power':{'dbData':telemetryDictionary['RW2 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW2 Speed/dir'],'fname':''}}}},
    { id: 'RW3', type: 'textbox',parentNode:'', position:{x:1425, y:1650}, data:{name:'Reaction Wheel 3', value:{'Power':{'dbData':telemetryDictionary['RW3 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW3 Speed/dir'],'fname':''}}}},
    { id: 'RW4', type: 'textbox',parentNode:'', position:{x:2125, y:1650}, data:{name:'Reaction Wheel 4', value:{'Power':{'dbData':telemetryDictionary['RW4 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW4 Speed/dir'],'fname':''}}}},

    { id: 'Thruster1', type: 'textbox',parentNode:'', position:{x:25, y:1900}, data:{name:'Thruster 1', value:{'Fire':{'dbData':telemetryDictionary['Thruster 1 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 1 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 1 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 1 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 1 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 1 Cat Bed Temp'],'fname':''}}}},
    { id: 'Thruster2', type: 'textbox',parentNode:'', position:{x:725, y:1900}, data:{name:'Thruster 2', value:{'Fire':{'dbData':telemetryDictionary['Thruster 2 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 2 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 2 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 2 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 2 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 2 Cat Bed Temp'],'fname':''}}}},
    { id: 'Thruster3', type: 'textbox',parentNode:'', position:{x:1425, y:1900}, data:{name:'Thruster 3', value:{'Fire':{'dbData':telemetryDictionary['Thruster 3 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 3 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 3 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 3 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 3 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 3 Cat Bed Temp'],'fname':''}}}},
    { id: 'Thruster4', type: 'textbox',parentNode:'', position:{x:2125, y:1900}, data:{name:'Thruster 4', value:{'Fire':{'dbData':telemetryDictionary['Thruster 4 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 4 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 4 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 4 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 4 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 4 Cat Bed Temp'],'fname':''}}}},

    { id: 'sumBox1', type: 'sumBox1',parentNode:'', position:{x:2700, y:150}, data:{ value1:{'Launch Mode':{'dbData':telemetryDictionary['Launch Mode'],'fname':''},'Current State':{'dbData':telemetryDictionary['Current State'],'fname':''},"Current Solution":{"dbData":telemetryDictionary['Current Solution'],'fname':''},"Orbit State":{"dbData":telemetryDictionary['Orbit State'],'fname':''},"Attitude Valid":{"dbData":telemetryDictionary['Attitude Valid'],'fname':''},"Target Table":{"dbData":telemetryDictionary['Target Table'],'fname':''},"Fixed Frame Table":{"dbData":telemetryDictionary['Fixed Frame Table'],'fname':''},"Desired Control Frame":{"dbData":telemetryDictionary['Desired Control Frame'],'fname':''},"Desired Reference Frame":{"dbData":telemetryDictionary['Desired Reference Frame'],'fname':''},"Sun Dist":{"dbData":telemetryDictionary['Sun Dist'],'fname':''},"ICP Flag":{"dbData":telemetryDictionary['ICP Flag'],'fname':''},"Maneuver Done":{"dbData":telemetryDictionary['Maneuver Done'],'fname':''}}, value2: {'Pos X':{'dbData':telemetryDictionary['Pos X'],'fname':''},'Pos Y':{'dbData':telemetryDictionary['Pos Y'],'fname':''},"Pos Z":{"dbData":telemetryDictionary['Pos Z'],'fname':''}, "Vel X":{"dbData":telemetryDictionary['Vel X'],'fname':''}, "Vel Y":{"dbData":telemetryDictionary['Vel Y'],'fname':''}, "Vel Z":{"dbData":telemetryDictionary['Vel Z'],'fname':''}}}},
    { id: 'sumBox3', type: 'sumBox3',parentNode:'', position:{x:2700, y:1700}, data:{totalSysX: telemetryDictionary['Total Sys X'],totalSysY: telemetryDictionary['Total Sys Y'],totalSysZ: telemetryDictionary['Total Sys Z'], value1:{"Mom Control":{"dbData":telemetryDictionary['Thruster Mom'],'fname':''},"DeSat In Progress":{"dbData":telemetryDictionary['DeSat In Progress'],'fname':''},"DeSat Fault":{"dbData":telemetryDictionary['DeSat Fault'],'fname':''}}, value2: {'Total Sys X':{'dbData':telemetryDictionary['Total Sys X'],'fname':''},'Total Sys Y':{'dbData':telemetryDictionary['Total Sys Y'],'fname':''},"Total Sys Z":{"dbData":telemetryDictionary['Total Sys Z'],'fname':''}, "Body X":{"dbData":telemetryDictionary['Body X'],'fname':''}, "Body Y":{"dbData":telemetryDictionary['Body Y'],'fname':''}, "Body Z":{"dbData":telemetryDictionary['Body Z'],'fname':''}}}},
    { id: 'sumBox4', type: 'sumBox4',parentNode:'', position:{x:2700, y:2400}, data:{name:"Thruster",catBedPri:telemetryDictionary['Cat Bed Htr Pwr Pri'],catBedRed:telemetryDictionary['Cat Bed Htr Pwr Red'],Thruster12Arm:telemetryDictionary['Thruster 1&2 Arm Status'],Thruster34Arm:telemetryDictionary['Thruster 1&2 Arm Status'],value1:{'Thrusters Active':{'dbData':telemetryDictionary['Thrusters Active'],'fname':''},"Thruster Fault":{"dbData":telemetryDictionary['Thruster Fault'],'fname':''},"Latch Valve Pos":{"dbData":telemetryDictionary['Latch Valve Pos'],'fname':''}}}},

    { id: 'IRU', type: 'sumBox5',parentNode:'', position:{x:25, y:2400}, data:{name:'Inertial Reference Unit (IRU)',className:'iruContainer', value1:{'Pwr':{'dbData':telemetryDictionary['IRU Pwr'],'fname':''}, 'Gryo1 Data Used':{'dbData':telemetryDictionary['IRU Gryo1 data used'],'fname':''}, 'Gryo2 Data Used':{'dbData':telemetryDictionary['IRU Gryo2 data used'],'fname':''}, 'Gryo3 Data Used':{'dbData':telemetryDictionary['IRU Gryo3 data used'],'fname':''}, 'Gryo Bias Est':{'dbData':telemetryDictionary['IRU Gryo Est Status'],'fname':''}, "Sensor Good":{"dbData":telemetryDictionary['IRU Sensor Good'],'fname':''}, "Message Status":{"dbData":telemetryDictionary['IRU Message Status'],'fname':''}}}},
    { id: 'Star Tracker', type: 'sumBox5',parentNode:'', position:{x:1800, y:2400}, data:{name:'Star Tracker',className:'stContainer', value1:{'Pwr':{'dbData':telemetryDictionary['St Pwr'],'fname':''}, 'Mode':{'dbData':telemetryDictionary['St Mode'],'fname':''}, 'Attitude Status':{'dbData':telemetryDictionary['St Att Status'],'fname':''}, 'St Used for Est':{'dbData':telemetryDictionary['St Used?'],'fname':''}, 'Synch Status':{'dbData':telemetryDictionary['St Synch Status'],'fname':''}, "Last Err Id":{"dbData":telemetryDictionary['St Last Err Id'],'fname':''}}}},
  
    { id: 'InfoBox', type: 'vmodeBox', parentNode: '', position: { x: 25, y: -65 }, data: { name: '', playBackValue: { time: pbTimeRef.current, loop: pbLoopRef.current }, 'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},

]);
 
  const createNodes = useCallback((telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:575, y:150}, data:{'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},

      { id: 'St Oh1', type: 'textbox',parentNode:'', position:{x:1025, y:2400}, data:{name:'Star Tracker Optical Heads 1',className:'stohContainer', value:{'Oh state':{'dbData':telemetryDictionary['St Oh1 Oh Stat'],'fname':''},'Expected Stars':{'dbData':telemetryDictionary['St Oh1 Expected Stars'],'fname':''}, "Coh Stars":{"dbData":telemetryDictionary['St Oh1 # Coh Stars'],'fname':''}}}},
      { id: 'St Oh2', type: 'textbox',parentNode:'', position:{x:1025, y:2750}, data:{name:'Star Tracker Optical Heads 2',className:'stohContainer', value:{'Oh state':{'dbData':telemetryDictionary['St Oh1 Oh Stat'],'fname':''},'Expected Stars':{'dbData':telemetryDictionary['St Oh1 Expected Stars'],'fname':''}, "Coh Stars":{"dbData":telemetryDictionary['St Oh1 # Coh Stars'],'fname':''}}}},

      { id: 'SS1', type: 'sunSensor',parentNode:'', position:{x:25, y:150}, data:{name:'Sun Sensor 1', 'Intensity':telemetryDictionary['SS1 Intensity'], 'DataUsed':telemetryDictionary['SS1 Data Used'],'fname':''}},
      { id: 'SS2', type: 'sunSensor',parentNode:'', position:{x:25, y:400}, data:{name:'Sun Sensor 2', 'Intensity':telemetryDictionary['SS2 Intensity'], 'DataUsed':telemetryDictionary['SS2 Data Used'],'fname':''}},
      { id: 'SS3', type: 'sunSensor',parentNode:'', position:{x:25, y:650}, data:{name:'Sun Sensor 3', 'Intensity':telemetryDictionary['SS3 Intensity'], 'DataUsed':telemetryDictionary['SS3 Data Used'],'fname':''}},
      { id: 'SS4', type: 'sunSensor',parentNode:'', position:{x:25, y:900}, data:{name:'Sun Sensor 4', 'Intensity':telemetryDictionary['SS4 Intensity'], 'DataUsed':telemetryDictionary['SS4 Data Used'],'fname':''}},
      { id: 'SS5', type: 'sunSensor',parentNode:'', position:{x:25, y:1150}, data:{name:'Sun Sensor 5', 'Intensity':telemetryDictionary['SS5 Intensity'], 'DataUsed':telemetryDictionary['SS5 Data Used'],'fname':''}},
      { id: 'SS6', type: 'sunSensor',parentNode:'', position:{x:25, y:1400}, data:{name:'Sun Sensor 6', 'Intensity':telemetryDictionary['SS6 Intensity'], 'DataUsed':telemetryDictionary['SS6 Data Used'],'fname':''}},

      { id: 'SS7', type: 'sunSensor',parentNode:'', position:{x:2175, y:150}, data:{name:'Sun Sensor 7', 'Intensity':telemetryDictionary['SS7 Intensity'], 'DataUsed':telemetryDictionary['SS7 Data Used'],'fname':''}},
      { id: 'SS8', type: 'sunSensor',parentNode:'', position:{x:2175, y:400}, data:{name:'Sun Sensor 8', 'Intensity':telemetryDictionary['SS8 Intensity'], 'DataUsed':telemetryDictionary['SS8 Data Used'],'fname':''}},
      { id: 'SS9', type: 'sunSensor',parentNode:'', position:{x:2175, y:650}, data:{name:'Sun Sensor 9', 'Intensity':telemetryDictionary['SS9 Intensity'], 'DataUsed':telemetryDictionary['SS9 Data Used'],'fname':''}},
      { id: 'SS10', type: 'sunSensor',parentNode:'', position:{x:2175, y:900}, data:{name:'Sun Sensor 10', 'Intensity':telemetryDictionary['SS10 Intensity'], 'DataUsed':telemetryDictionary['SS10 Data Used'],'fname':''}},
      { id: 'SS11', type: 'sunSensor',parentNode:'', position:{x:2175, y:1150}, data:{name:'Sun Sensor 11', 'Intensity':telemetryDictionary['SS11 Intensity'], 'DataUsed':telemetryDictionary['SS11 Data Used'],'fname':''}},
      { id: 'SS12', type: 'sunSensor',parentNode:'', position:{x:2175, y:1400}, data:{name:'Sun Sensor 12', 'Intensity':telemetryDictionary['SS12 Intensity'], 'DataUsed':telemetryDictionary['SS12 Data Used'],'fname':''}},
   
      { id: 'RW1', type: 'textbox',parentNode:'', position:{x:25, y:1650}, data:{name:'Reaction Wheel 1', value:{'Power':{'dbData':telemetryDictionary['RW1 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW1 Speed/dir'],'fname':''}}}},
      { id: 'RW2', type: 'textbox',parentNode:'', position:{x:725, y:1650}, data:{name:'Reaction Wheel 2', value:{'Power':{'dbData':telemetryDictionary['RW2 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW2 Speed/dir'],'fname':''}}}},
      { id: 'RW3', type: 'textbox',parentNode:'', position:{x:1425, y:1650}, data:{name:'Reaction Wheel 3', value:{'Power':{'dbData':telemetryDictionary['RW3 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW3 Speed/dir'],'fname':''}}}},
      { id: 'RW4', type: 'textbox',parentNode:'', position:{x:2125, y:1650}, data:{name:'Reaction Wheel 4', value:{'Power':{'dbData':telemetryDictionary['RW4 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW4 Speed/dir'],'fname':''}}}},

      { id: 'Thruster1', type: 'textbox',parentNode:'', position:{x:25, y:1900}, data:{name:'Thruster 1', value:{'Fire':{'dbData':telemetryDictionary['Thruster 1 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 1 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 1 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 1 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 1 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 1 Cat Bed Temp'],'fname':''}}}},
      { id: 'Thruster2', type: 'textbox',parentNode:'', position:{x:725, y:1900}, data:{name:'Thruster 2', value:{'Fire':{'dbData':telemetryDictionary['Thruster 2 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 2 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 2 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 2 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 2 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 2 Cat Bed Temp'],'fname':''}}}},
      { id: 'Thruster3', type: 'textbox',parentNode:'', position:{x:1425, y:1900}, data:{name:'Thruster 3', value:{'Fire':{'dbData':telemetryDictionary['Thruster 3 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 3 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 3 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 3 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 3 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 3 Cat Bed Temp'],'fname':''}}}},
      { id: 'Thruster4', type: 'textbox',parentNode:'', position:{x:2125, y:1900}, data:{name:'Thruster 4', value:{'Fire':{'dbData':telemetryDictionary['Thruster 4 Fire'],'fname':''}, 'Cmd Start':{'dbData':telemetryDictionary['Thruster 4 Cmd Start'],'fname':''},'Stop':{'dbData':telemetryDictionary['Thruster 4 Stop'],'fname':''}, 'Duration':{'dbData':telemetryDictionary['Thruster 4 Duration'],'fname':''},'Duty':{'dbData':telemetryDictionary['Thruster 4 Duty'],'fname':''}, 'Cat Bed Temp':{'dbData':telemetryDictionary['Thruster 4 Cat Bed Temp'],'fname':''}}}},

      { id: 'sumBox1', type: 'sumBox1',parentNode:'', position:{x:2700, y:150}, data:{ value1:{'Launch Mode':{'dbData':telemetryDictionary['Launch Mode'],'fname':''},'Current State':{'dbData':telemetryDictionary['Current State'],'fname':''},"Current Solution":{"dbData":telemetryDictionary['Current Solution'],'fname':''},"Orbit State":{"dbData":telemetryDictionary['Orbit State'],'fname':''},"Attitude Valid":{"dbData":telemetryDictionary['Attitude Valid'],'fname':''},"Target Table":{"dbData":telemetryDictionary['Target Table'],'fname':''},"Fixed Frame Table":{"dbData":telemetryDictionary['Fixed Frame Table'],'fname':''},"Desired Control Frame":{"dbData":telemetryDictionary['Desired Control Frame'],'fname':''},"Desired Reference Frame":{"dbData":telemetryDictionary['Desired Reference Frame'],'fname':''},"Sun Dist":{"dbData":telemetryDictionary['Sun Dist'],'fname':''},"ICP Flag":{"dbData":telemetryDictionary['ICP Flag'],'fname':''},"Maneuver Done":{"dbData":telemetryDictionary['Maneuver Done'],'fname':''}}, value2: {'Pos X':{'dbData':telemetryDictionary['Pos X'],'fname':''},'Pos Y':{'dbData':telemetryDictionary['Pos Y'],'fname':''},"Pos Z":{"dbData":telemetryDictionary['Pos Z'],'fname':''}, "Vel X":{"dbData":telemetryDictionary['Vel X'],'fname':''}, "Vel Y":{"dbData":telemetryDictionary['Vel Y'],'fname':''}, "Vel Z":{"dbData":telemetryDictionary['Vel Z'],'fname':''}}}},
      { id: 'sumBox3', type: 'sumBox3',parentNode:'', position:{x:2700, y:1700}, data:{totalSysX: telemetryDictionary['Total Sys X'],totalSysY: telemetryDictionary['Total Sys Y'],totalSysZ: telemetryDictionary['Total Sys Z'], value1:{"Mom Control":{"dbData":telemetryDictionary['Thruster Mom'],'fname':''},"DeSat In Progress":{"dbData":telemetryDictionary['DeSat In Progress'],'fname':''},"DeSat Fault":{"dbData":telemetryDictionary['DeSat Fault'],'fname':''}}, value2: {'Total Sys X':{'dbData':telemetryDictionary['Total Sys X'],'fname':''},'Total Sys Y':{'dbData':telemetryDictionary['Total Sys Y'],'fname':''},"Total Sys Z":{"dbData":telemetryDictionary['Total Sys Z'],'fname':''}, "Body X":{"dbData":telemetryDictionary['Body X'],'fname':''}, "Body Y":{"dbData":telemetryDictionary['Body Y'],'fname':''}, "Body Z":{"dbData":telemetryDictionary['Body Z'],'fname':''}}}},
      { id: 'sumBox4', type: 'sumBox4',parentNode:'', position:{x:2700, y:2400}, data:{name:"Thruster",catBedPri:telemetryDictionary['Cat Bed Htr Pwr Pri'],catBedRed:telemetryDictionary['Cat Bed Htr Pwr Red'],Thruster12Arm:telemetryDictionary['Thruster 1&2 Arm Status'],Thruster34Arm:telemetryDictionary['Thruster 1&2 Arm Status'],value1:{'Thrusters Active':{'dbData':telemetryDictionary['Thrusters Active'],'fname':''},"Thruster Fault":{"dbData":telemetryDictionary['Thruster Fault'],'fname':''},"Latch Valve Pos":{"dbData":telemetryDictionary['Latch Valve Pos'],'fname':''}}}},
  
      { id: 'IRU', type: 'sumBox5',parentNode:'', position:{x:25, y:2400}, data:{name:'Inertial Reference Unit (IRU)',className:'iruContainer', value1:{'Pwr':{'dbData':telemetryDictionary['IRU Pwr'],'fname':''}, 'Gryo1 Data Used':{'dbData':telemetryDictionary['IRU Gryo1 data used'],'fname':''}, 'Gryo2 Data Used':{'dbData':telemetryDictionary['IRU Gryo2 data used'],'fname':''}, 'Gryo3 Data Used':{'dbData':telemetryDictionary['IRU Gryo3 data used'],'fname':''}, 'Gryo Bias Est':{'dbData':telemetryDictionary['IRU Gryo Est Status'],'fname':''}, "Sensor Good":{"dbData":telemetryDictionary['IRU Sensor Good'],'fname':''}, "Message Status":{"dbData":telemetryDictionary['IRU Message Status'],'fname':''}}}},
      { id: 'Star Tracker', type: 'sumBox5',parentNode:'', position:{x:1800, y:2400}, data:{name:'Star Tracker',className:'stContainer', value1:{'Pwr':{'dbData':telemetryDictionary['St Pwr'],'fname':''}, 'Mode':{'dbData':telemetryDictionary['St Mode'],'fname':''}, 'Attitude Status':{'dbData':telemetryDictionary['St Att Status'],'fname':''}, 'St Used for Est':{'dbData':telemetryDictionary['St Used?'],'fname':''}, 'Synch Status':{'dbData':telemetryDictionary['St Synch Status'],'fname':''}, "Last Err Id":{"dbData":telemetryDictionary['St Last Err Id'],'fname':''}}}},
    
      { id: 'InfoBox', type: 'vmodeBox', parentNode: '', position: { x: 25, y: -65 }, data: { name: '', playBackValue: { time: pbTimeRef.current, loop: pbLoopRef.current }, 'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},
 
    ]}, []);
     
 
    useEffect(() => { 
      if (!guiValues.current.pbisInPlayBack) {
        return;
      }
    
      let isCancelled = false;
    
      const processData = async () => { 
        // Initialize an empty object to store telemetry data samples
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;

    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            }
          });
    
          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData);
          setNodes(newNodes);
    
          // Log the current loop progress
          //console.log(`${i + 1}/${totalLoops}`);
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay()
        //console.log('Playback completed');
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true; 
      };
    }, [createNodes, influxData, guiValues]);

    useEffect(() => {
      //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
      if (guiValues.current.pbisInPlayBack) {
        return;
      }
      const telemetryDictionary1 = createTelemetryDictionary();
    
      const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [createNodes, createTelemetryDictionary, guiValues]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};

  const activeAnn = (enabled: TLM, name: string) => {

    if (enabled === undefined || enabled === null || !enabled) {
      return 8
    }

    const edgeColor = enabled && enabled.telemetry && enabled.telemetry.includes(name) ? 'green' : 'white';
    const opa = edgeColor === 'green' ? 9 : 8;
    return opa
  }

  const initialEdges = [
  {id: 'TtoG', source: 'Tele Subsystem',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge1', source: 'Ground Subsystem',sourceHandle: 'source-left-1', target: 'MOC',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge2', source: 'MOC',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'GtoT', source: 'Ground Subsystem',sourceHandle: 'source-right', target: 'Tele Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  //{id: 'edge4', source: 'Tele Subsystem',sourceHandle: 'source-left-1', target: 'CDH Subsystem',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge4', source: 'SP4T',sourceHandle: 'source-right', target: 'ScSketch',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge5', source: 'ScSketch',sourceHandle: 'source-left-1', target: 'LNA',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},

  {id: 'edge6', source: 'S-Band Transponder',sourceHandle: 'source-right-1', target: 'SP2T',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge7', source: 'SP2T',sourceHandle: 'source-left-1', target: 'S-Band Transponder',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge8', source: 'SPOC',sourceHandle: 'source-right-2', target: 'S-Band Transponder',targetHandle:'target-left-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  {id: 'edge9', source: 'S-Band Transponder',sourceHandle: 'source-left-2', target: 'SPOC',targetHandle:'target-right-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge12', source: 'SPOC',sourceHandle: 'source-left-3', target: 'Supervisor',targetHandle:'target-right-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge13', source: 'Supervisor',sourceHandle: 'source-right-3', target: 'SPOC',targetHandle:'target-left-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge14', source: 'SP2T',sourceHandle: 'source-top', target: 'HPA',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge15', source: 'HPA',sourceHandle: 'source-right', target: 'SP4T',targetHandle:'target-left-4', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge21', source: 'MGA Rx',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Mga'), data:{enabled:telemetryDictionary['Ant State'], name:'Mga'}},
  {id: 'edge22', source: 'LGA Rx 1',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga1'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga1'}},
  {id: 'edge23', source: 'LGA Rx 2',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga2'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga2'}},

  {id: 'edge24', source: 'LNA',sourceHandle: 'source-top', target: 'SP4T',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge25', source: 'SP4T',sourceHandle: 'source-left-2', target: 'SP2T',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow
          edges={initialEdges}
          onInit={onInit}
          edgeTypes={edgeTypes}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          fitView={true}
          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default App;
